<template>
  <div
    class="popup_wrap"
    style="width:800px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <form id="mailFrm">
      <div class="popup_cont">
        <h1 class="page_title">REMARK 내용</h1>
        <div class="content_box" style="height: 300px;"><!-- content_box -->
          <th>{{ rmk }}</th>
        </div><!-- content_box// -->
      </div>
    </form>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'

export default {
  name: 'KmtcCouponRmkPop',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          rmk: ''
        }
      }
    }
  },
  data () {
    return {
      rmk: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
    }
    this.initParam()
  },
  methods: {
    // init parameter
    async initParam () {
      this.rmk = this.parentInfo.rmk
    }
  }
}
</script>

<style scoped>
  .tbl_search th {vertical-align: top;}
</style>
