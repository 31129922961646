var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("form", { attrs: { id: "mailFrm" } }, [
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("REMARK 내용")]),
          _c(
            "div",
            { staticClass: "content_box", staticStyle: { height: "300px" } },
            [_c("th", [_vm._v(_vm._s(_vm.rmk))])]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }